<template>
  <AdminTemplate>
    <el-col :span="18" :offset="3">
      <div class="userAnswer bg-primary pd-5 text-white text-center">
        <p class="mg-y-0 font-36">
          {{ userAnswer.total }}/{{ room.players.length }}
        </p>
        <p class="mg-y-0 font-32">ส่งคำตอบ</p>
      </div>
      <div
        class="font-34 text-danger text-center mg-t-3"
        :class="
          countTime < 6
            ? 'animate__fadeIn animate__animated animate__infinite'
            : null
        "
      >
        <i class="fas fa-clock"></i> เหลือเวลา {{ countTime }} วินาที
      </div>
      <h1 class="text-center mg-y-1">{{ exam.question }}</h1>
      <div
        v-for="(data, index) in exam.choice"
        :key="index"
        class="border-choice position-relative"
        :class="
          index == 0
            ? 'border-primary'
            : index == 1
            ? 'border-danger'
            : 'border-warning'
        "
      >
        <div
          :class="
            index == 0 ? 'bg-primary' : index == 1 ? 'bg-danger' : 'bg-warning'
          "
          class="text-white font-44 border-circle"
        >
          {{ index + 1 }}
        </div>
        <span class="font-24">{{ data }}</span>
      </div>
      <el-col :span="12" :offset="6" class="mg-b-5 mg-t-6">
        <el-button type="primary" class="w-100" @click="nextStep()"
          >ดูผลคะแนน</el-button
        >
      </el-col>
    </el-col>
  </AdminTemplate>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import AdminTemplate from "@/template/VoteAdminTemplate";
export default {
  components: {
    AdminTemplate
  },
  props: {
    routeParams: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      userAnswer: {
        one: [],
        two: [],
        three: [],
        total: 0
      },
      countTime: 15
    };
  },

  mounted() {
    this.intervalation = setInterval(() => {
      this.getUserAnswer();
      this.getRoom();
    }, 1000);
    this.countdown();
  },
  beforeDestroy() {
    clearInterval(this.intervalation);
  },
  methods: {
    countdown() {
      if (this.countTime <= 0) {
        return false;
      }
      setTimeout(() => {
        setTimeout(() => {
          this.countTime--;
          this.countdown();
        }, 1000);
      }, 600);
    },
    getUserAnswer ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("/get/answer", {
        roomId: this.room.roomId,
        qId: this.step.qId
      }).then(res => {
        // console.log("answer", res);
        if (res.data.success) {
          this.userAnswer.one = res.data.obj.one;
          this.userAnswer.two = res.data.obj.two;
          this.userAnswer.three = res.data.obj.three;
          this.userAnswer.total =
            res.data.obj.three.length +
            res.data.obj.two.length +
            res.data.obj.one.length;
        }
        // console.log("res", res);
      });
    },
    nextStep() {
      let obj = {
        roomId: this.room.roomId,
        qId: this.step.qId != "undefined" ? this.step.qId : this.step.qid,
        attId: this.step.attId,
        total: true,
        state: "waiting answer"
      };
      this.setState(obj);
      this.$router.push("/answer");
    }
  },
  computed: {
    allExam() {
      return this.$store.state.exam;
    },
    exam() {
      if (typeof this.step != "undefined") {
        let no = this.step.qId;
        let step = this.step.attId;
      }
      let data = this.$store.state.exam.filter(a => a.no == this.step.qId);
      if (data.length > 0) {
        return data[0];
      } else {
        return "";
      }
    }
  }
};
</script>
<style scoped>
.border-choice {
  border: 1px solid;
  margin: 20px 0;
  padding: 25px 60px;
  border-radius: 5px;
}
.border-circle {
  top: 5%;
  left: -35px;
  position: absolute;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userAnswer {
  position: absolute;
  right: 0;
}
</style>